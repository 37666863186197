"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./images/*.{png,jpg,jpeg,gif,svg}";

// import stylu
import "./index.scss";

// NodeList.prototype.forEach() polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// dalsi JS

// carousel
import "./scripts/carousel";

// SmoothScroll inicializace
import SmoothScroll from "smooth-scroll";
new SmoothScroll('a[href*="#"]', {
  speed: 300,
  speedAsDuration: true
});


// galerie SimpleLightbox - BEGIN
// https://simplelightbox.com
import SimpleLightbox from "simplelightbox";
import "simplelightbox/src/simple-lightbox.scss";
window.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("*[data-gallery]").forEach((gallery) => {
    new SimpleLightbox("#" + gallery.getAttribute("id") + " a", {
      /* options */
    });
  });
});
// galerie SimpleLightbox - BEGIN

import GLightbox from 'glightbox';
import "glightbox/dist/css/glightbox.min.css";
document.addEventListener("DOMContentLoaded", function () {
const lightbox = GLightbox({
  touchNavigation: true,
  loop: true,
  autoplayVideos: true
});
});

//main menu

document.addEventListener("DOMContentLoaded", function () {
   const menuToggle = document.getElementById("menuToggle");
  const menu = document.getElementById("menu");
  menuToggle.addEventListener("click", (event) => {
    menu.classList.toggle("open");
  }, false);
});


//scrollování
document.addEventListener("DOMContentLoaded", function () {
  document.addEventListener('scroll', (event) => {
      const scrolledMenu = document.getElementById("scrolledMenu");
      if (window.scrollY > 5) {
        if(!scrolledMenu.classList.contains('scrolled')) {
            scrolledMenu.classList.add('scrolled');
        }
      } else {
        if(scrolledMenu.classList.contains('scrolled')) {
          scrolledMenu.classList.remove('scrolled');
        }
      }
      //console.log('scrollnuto -' + window.scrollY);

    });
});
// paralax - START
import "intersection-observer";

const paralaxObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add(entry.target.dataset.paralax);
      paralaxObserver.unobserve(entry.target);
    }
  });
});

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".paraX").forEach((elm) => {
    paralaxObserver.observe(elm);
  });
});
// paralax - END
