// carousel
import { tns } from "tiny-slider/src/tiny-slider";
import "tiny-slider/dist/tiny-slider.css";

//CAROUSEL OPEN
document.addEventListener("DOMContentLoaded", function () {
    var carousel = document.getElementById("carousel");
    if (carousel) {
      tns({
        container: "#carousel",
        items: 1,
        slideBy: 1,
        navAsThumbnails: true,
        autoplay: true,
        mouseDrag: true,
        swipeAngle: false,
        gutter: 75,
        speed: 400,
        nav: true,
        controlsText: ["", ""]
      });
    }

    var carouselM = document.getElementById("carouselM");
    if (carouselM) {
      tns({
        container: "#carouselM",
        items: 1.3,
        slideBy: 1,
        navAsThumbnails: true,
        autoplay: true,
        mouseDrag: true,
        swipeAngle: false,
        gutter: 40,
        speed: 400,
        nav: true,
        controlsText: ["", ""],
        responsive: {
          650: { items: 2.2,  gutter: 20},
          768: { items: 3,  gutter: 30},
          1440: { items: 3,  gutter: 40 },
        }
      });
    }
    var carouselS = document.getElementById("carouselS");
    if (carouselS) {
      tns({
        container: "#carouselS",
        items: 1.3,
        slideBy: 1,
        navAsThumbnails: true,
        autoplay: true,
        mouseDrag: true,
        swipeAngle: false,
        gutter: 40,
        speed: 400,
        nav: true,
        controlsText: ["", ""],
        responsive: {
          650: { items: 2.2,  gutter: 20},
          768: { items: 3,  gutter: 30},
          1440: { items: 3,  gutter: 40 },
        }
      });
    }
});


